<i18n src="@/locales/serbia_dental_center.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-home-content-clinic.json" />

<template>
  <div class="clinic-serbia">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{
            $t('article_p1')
          }}
        </p>
        <p>
          {{
            $t('article_p2')
          }}
        </p>
        <ImanMoreInfo :path="$t('article_more_info_path_1')">
          {{ $t('article_more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <section class="section">
        <div class="columns is-centered">
          <div class="column is-half">
            <ImanVideo :video="video" />
          </div>
        </div>
      </section>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          <span v-html="$t('article_p3')" />
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[2]">
        <p>
          {{
            $t('article_p4')
          }}
        </p>
        <ImanMoreInfo :path="$t('article_more_info_path_2')">
          {{ $t('article_more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{
            $t('article_p5')
          }}
        </p>
        <p>
          {{
            $t('article_p6')
          }}
        </p>
        <ImanMoreInfo :path="$t('article_more_info_path_3')">
          {{ $t('article_more_info_3') }}
        </ImanMoreInfo>
      </ImanArticle>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video2" />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{
              $t('collapse_p1')
            }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <div v-html="$t('collapse_p2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{
              $t('collapse_p3')
            }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <div v-html="$t('collapse_p4')" />
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_4') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanCountryCard from "@/components/ImanCountryCard";
  import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";
  export default {
    name: 'DentalCenterSerbia',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
      ImanCountryCard
    },
    mixins: [
      metaMixin,
      articlesCardContentFilterMixin,
      cardHomeContentClinicMixin,
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [65, 67, 69, 52],
        countryOverlayClass: {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        },
        img: [
          {
            path: 'clinic/serbia/dentiste-belgrade-serbie.jpg',
            position: 'right'
          },
          {
            path: 'clinic/serbia/cabinet-dentaire-belgrade-serbie.jpg',
            position: 'right'
          },
          {
            path: 'clinic/serbia/soins-dentaire-serbie-belgrade.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/js2odpUoezQ'
        },
        video2: {
          src: 'https://www.youtube.com/embed/llZBPeg9KPI'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.3.png')
      },
      cardContentClinic () {
        return this.cardHomeContentClinic.filter((cardClinic) => cardClinic.id !== 0)
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 970 || document.documentElement.scrollTop > 970) {
          this.mainBackgroundClass = 'fixed-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
          this.countryOverlayClass = {
            overlay:'overlay show-overlay',
            element: 'element show-element'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/clinic/serbia/H1-cabinet-dentaire-belgrade-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/clinic/serbia/H1-cabinet-dentaire-belgrade-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../../assets/img/clinic/serbia/H1-cabinet-dentaire-belgrade-m.jpg");
    }
  }
</style>
